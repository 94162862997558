import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { BASEURL } from "../../Comman";
import { useLocation, useNavigate } from "react-router-dom";

const CourseForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [originalData, setOriginalData] = useState({});
  // Define state variables for form fields
  const [formData, setFormData] = useState({
    course_name: "",
    description: "",
    duration: "",
    course_fee: "",
    certificates: "",
    course_syllabus: "",
    batch_start: "",
    course_image: null,
  });

  const [errors, setErrors] = useState({});

  // Handle input change and update state
  const handleChange = (e) => {
    if (e.target.name === "course_image") {
      setFormData({
        ...formData,
        course_image: e.target.files[0], // for file input
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Validate form inputs
  const validate = () => {
    let tempErrors = {};
    if (!formData.course_name)
      tempErrors.course_name = "Course Name is required.";
    if (!formData.description)
      tempErrors.description = "Description is required.";
    if (!formData.duration) tempErrors.duration = "Duration is required.";
    if (!formData.course_fee) tempErrors.course_fee = "Course fee is required.";
    if (!formData.certificates)
      tempErrors.certificates = "Certificates info is required.";
    if (!formData.course_syllabus)
      tempErrors.course_syllabus = "Syllabus info is required.";
    if (!formData.batch_start)
      tempErrors.batch_start = "Batch start date is required.";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return; // Stop submission if validation fails

    const submissionData = new FormData();
    // Append only changed fields to submissionData for PUT request
    for (const key in formData) {
      if (formData[key] !== originalData[key]) {
        submissionData.append(key, formData[key]);
      }
    }

    try {
      // If editing (id exists), use PUT, otherwise use POST
      if (id) {
        const response = await axios.put(
          `${BASEURL}/courses/course-detail/${id}`,
          submissionData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data) {
          navigate("/admin-courses");
        }
      } else {
        const response = await axios.post(
          `${BASEURL}/courses/course-detail`,
          submissionData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data) {
          navigate("/admin-courses");
        }
      }
    } catch (error) {
      console.error("Error submitting form", error);
      alert("Failed to submit course!");
    }
  };

  const getCourseById = async (id) => {
    try {
      const response = await axios.get(
        `${BASEURL}/courses/course-detail/${id}`
      );
      if (response) {
        const AllData = response.data.data;
        setFormData({
          course_name: AllData.course_name,
          description: AllData.description,
          duration: AllData.duration,
          course_fee: AllData.course_fee,
          certificates: AllData.certificates,
          course_syllabus: AllData.course_syllabus,
          batch_start: AllData.batch_start,
          course_image: AllData.course_image,
        });
        setOriginalData(AllData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const CourseId = location.state;
    setId(CourseId);
    if (CourseId) {
      getCourseById(CourseId);
    }
  }, []);
  return (
    <div className="container mt-5">
      <h2 className="mb-4">{id ? "Edit" : " Add"} Course</h2>
      <form onSubmit={handleSubmit} className="needs-validation">
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="course_name" className="form-label">
              Course Name
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.course_name ? "is-invalid" : ""
              }`}
              id="course_name"
              name="course_name"
              value={formData.course_name}
              onChange={handleChange}
            />
            {errors.course_name && (
              <div className="invalid-feedback">{errors.course_name}</div>
            )}
          </div>

          <div className="col-md-6">
            <label htmlFor="course_fee" className="form-label">
              Course Fee
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.course_fee ? "is-invalid" : ""
              }`}
              id="course_fee"
              name="course_fee"
              value={formData.course_fee}
              onChange={handleChange}
            />
            {errors.course_fee && (
              <div className="invalid-feedback">{errors.course_fee}</div>
            )}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="duration" className="form-label">
              Duration (in hours)
            </label>
            <input
              type="text"
              className={`form-control ${errors.duration ? "is-invalid" : ""}`}
              id="duration"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
            />
            {errors.duration && (
              <div className="invalid-feedback">{errors.duration}</div>
            )}
          </div>

          <div className="col-md-6">
            <label htmlFor="certificates" className="form-label">
              Certificates
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.certificates ? "is-invalid" : ""
              }`}
              id="certificates"
              name="certificates"
              value={formData.certificates}
              onChange={handleChange}
            />
            {errors.certificates && (
              <div className="invalid-feedback">{errors.certificates}</div>
            )}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="course_syllabus" className="form-label">
              Course Syllabus
            </label>
            <input
              type="text"
              className={`form-control ${
                errors.course_syllabus ? "is-invalid" : ""
              }`}
              id="course_syllabus"
              name="course_syllabus"
              value={formData.course_syllabus}
              onChange={handleChange}
            />
            {errors.course_syllabus && (
              <div className="invalid-feedback">{errors.course_syllabus}</div>
            )}
          </div>

          <div className="col-md-6">
            <label htmlFor="batch_start" className="form-label">
              Batch Start Date
            </label>
            <input
              type="date"
              className={`form-control ${
                errors.batch_start ? "is-invalid" : ""
              }`}
              id="batch_start"
              name="batch_start"
              value={formData.batch_start}
              onChange={handleChange}
            />
            {errors.batch_start && (
              <div className="invalid-feedback">{errors.batch_start}</div>
            )}
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            className={`form-control ${errors.description ? "is-invalid" : ""}`}
            id="description"
            name="description"
            rows="4"
            value={formData.description}
            onChange={handleChange}
          />
          {errors.description && (
            <div className="invalid-feedback">{errors.description}</div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="course_image" className="form-label">
            Course Image
          </label>
          <input
            type="file"
            className="form-control"
            id="course_image"
            name="course_image"
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="btn btn-danger">
          Submit Course
        </button>
      </form>
    </div>
  );
};

export default CourseForm;
