import React from "react";
import { Container, Row } from "react-bootstrap";
import Navbar from "./Navbar";
import Section from "./Section";
import Footer from "./Footer";

const Termsconditions = () => {
  return (
    <>
      <Navbar />
      <Container>
        <Row>
          <div className="section-heading">
            <h2>Terms and conditions</h2>
          </div>
        </Row>
        <Container>
          <Row>
            <h5>
              These terms and conditions outline the rules and regulations for
              the use of connecting careers Website, located at
              https://connectingcareers.in/.
            </h5>
            <p>
              By accessing this website we assume you accept these terms and
              conditions. Do not continue to use connecting careers if you do
              not agree to take all of the terms and conditions stated on this
              page.
            </p>
            <p>
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and all Agreements:
              "Client", "You" and "Your" refers to you, the person log on this
              website and compliant to the Company's terms and conditions. "The
              Company", "Ourselves", "We", "Our" and "Us", refers to our
              Company. "Party", "Parties", or "Us", refers to both the Client
              and ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner for the
              express purpose of meeting the Client's needs in respect of
              provision of the Company's stated services, in accordance with and
              subject to, prevailing law of af. Any use of the above terminology
              or other words in the singular, plural, capitalization and/or
              he/she or they, are taken as interchangeable and therefore as
              referring to same.
            </p>
            <h5>Cookies</h5>
            <p>
              We employ the use of cookies. By accessing connecting careers, you
              agreed to use cookies in agreement with the connecting careers's
              Privacy Policy. Most interactive websites use cookies to let us
              retrieve the user's details for each visit. Cookies are used by
              our website to enable the functionality of certain areas to make
              it easier for people visiting our website. Some of our
              affiliate/advertising partners may also use cookies.
            </p>
            <h5>License</h5>
            <p>
              Unless otherwise stated, connecting careers and/or its licensors
              own the intellectual property rights for all material on
              connecting careers. All intellectual property rights are reserved.
              You may access this from connecting careers for your own personal
              use subjected to restrictions set in these terms and conditions.
            </p>
            <strong>You must not:</strong>
            <ul>
              <li>Republish material from connecting careers</li>
              <li>
                Sell, rent or sub-license material from connecting careers
              </li>
              <li>
                Reproduce, duplicate or copy material from connecting careers
              </li>
              <li>Redistribute content from connecting careers</li>
              <li>
                This Agreement shall begin on the date hereof. Our Terms and
                Conditions were created with the help of the Free Terms and
                Conditions Generator.
              </li>
              <li>
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. connecting careers does not filter, edit, publish or
                review Comments prior to their presence on the website. Comments
                do not reflect the views and opinions of connecting careers,its
                agents and/or affiliates. Comments reflect the views and
                opinions of the person who post their views and opinions. To the
                extent permitted by applicable laws, connecting careers shall
                not be liable for the Comments or for any liability, damages or
                expenses caused and/or suffered as a result of any use of and/or
                posting of and/or appearance of the Comments on this website.
              </li>
              <i>
                connecting careers reserves the right to monitor all Comments
                and to remove any Comments which can be considered
                inappropriate, offensive or causes breach of these Terms and
                Conditions. You warrant and represent that:
              </i>
              <li>
                You are entitled to post the Comments on our website and have
                all necessary licenses and consents to do so;
              </li>
              <li>
                The Comments do not invade any intellectual property right,
                including without limitation copyright, patent or trademark of
                any third party;
              </li>
              <li>
                The Comments do not contain any defamatory, libelous, offensive,
                indecent or otherwise unlawful material which is an invasion of
                privacy
              </li>
              <li>
                The Comments will not be used to solicit or promote business or
                custom or present commercial activities or unlawful activity.
              </li>
              <li>
                You hereby grant connecting careers a non-exclusive license to
                use, reproduce, edit and authorize others to use, reproduce and
                edit any of your Comments in any and all forms, formats or
                media.
              </li>
            </ul>
            <h5>Hyperlinking to our Content</h5>
            <ul>
              <li>News organizations;</li>
              <li>Search engines;</li>
              <li>Government agencies;</li>
            </ul>
            <p>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and System wide Accredited Businesses except
              soliciting non-profit organizations, charity shopping malls, and
              charity fundraising groups which may not hyperlink to our Web
              site. These organizations may link to our home page, to
              publications or to other Website information so long as the link:
              (a) is not in any way deceptive; (b) does not falsely imply
              sponsorship, endorsement or approval of the linking party and its
              products and/or services; and (c) fits within the context of the
              linking party's site.
            </p>
            <p>
              We may consider and approve other link requests from the following
              types of organizations:
            </p>
            <p>commonly-known consumer and/or business information sources;</p>
            <p>dot.in community sites;</p>
            <h5>Content Liability</h5>
            <p>
              We shall not be hold responsible for any content that appears on
              your Website. You agree to protect and defend us against all
              claims that is rising on your Website. No link(s) should appear on
              any Website that may be interpreted as libelous, obscene or
              criminal, or which infringes, otherwise violates, or advocates the
              infringement or other violation of, any third party rights.
            </p>
            <h5>Reservation of Rights</h5>
            <p>
              We reserve the right to request that you remove all links or any
              particular link to our Website. You approve to immediately remove
              all links to our Website upon request. We also reserve the right
              to amen these terms and conditions and it's linking policy at any
              time. By continuously linking to our Website, you agree to be
              bound to and follow these linking terms and conditions.
            </p>
            <h5>Removal of links from our website</h5>
            <p>
              If you find any link on our Website that is offensive for any
              reason, you are free to contact and inform us any moment. We will
              consider requests to remove links but we are not obligated to or
              so or to respond to you directly.
            </p>
            <p>
              We do not ensure that the information on this website is correct,
              we do not warrant its completeness or accuracy; nor do we promise
              to ensure that the website remains available or that the material
              on the website is kept up to date.
            </p>
            <h5>Disclaimer</h5>
            <p>
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website. Nothing in this disclaimer will:limit
              or exclude our or your liability for death or personal injury;
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation; limit any of our or your liabilities in any way
              that is not permitted under applicable law; or exclude any of our
              or your liabilities that may not be excluded under applicable law.
            </p>
            <p>
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer: (a) are subject to the preceding
              paragraph; and (b) govern all liabilities arising under the
              disclaimer, including liabilities arising in contract, in tort and
              for breach of statutory duty.
            </p>
            <p>
              As long as the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
          </Row>
        </Container>
      </Container>
      <Section />
      <Footer />
    </>
  );
};

export default Termsconditions;
