import React, { useState } from "react";
import "./PaymentPage.css";
import axios from "axios";
import { BASEURL } from "./Comman";
import { Col, Container, Row } from "react-bootstrap";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const PaymentPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const formErrors = {};
    let isValid = true;

    if (!name) {
      formErrors.name = "Name is required.";
      isValid = false;
    }

    // Email validation
    if (!email) {
      formErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email is invalid.";
      isValid = false;
    }

    // WhatsApp number validation
    if (!whatsapp) {
      formErrors.whatsapp = "WhatsApp number is required.";
      isValid = false;
    } else if (!/^[0-9]{10}$/.test(whatsapp)) {
      formErrors.whatsapp = "WhatsApp number should be 10 digits.";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    try {
      const payload = {
        name: name,
        whatsapp_no: whatsapp,
        receipt: null,
        amount: 199,
        email: email,
        transaction_id: "",
        payment_type: "UPI",
      };
      const response = await axios.post(
        `${BASEURL}/courses/book-seminar`,
        payload
      );
      if (response.data) {
        const redirectUrl = response.data.pay_page_url;
        window.location.href = redirectUrl;
      }
      // setIsFormSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="payment-page ">
        <div className="container">
          {/* Main Section */}
          <div className="main-content">
            {/* Left Section: Course Details */}
            <div className="course-details">
              <div className="left-section">
                <img
                  src="/group-1000006094-1@2x.png"
                  alt="Professional"
                  className="img-fluid"
                />
              </div>
            </div>

            {/* Right Section: Payment Form */}
            <div className="payment-form" style={{ position: "relative" }}>
              <div className="payment-form-absolute">
                <h2>Payment Details</h2>
                <form onSubmit={handleSubmit}>
                  {/* Amount field */}
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="text"
                    id="amount"
                    value="199"
                    placeholder="₹199.00"
                    readOnly
                  />

                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {errors.name && (
                    <span className="error-text">{errors.name}</span>
                  )}

                  {/* Email field */}
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <span className="error-text">{errors.email}</span>
                  )}

                  {/* WhatsApp number field */}
                  <label htmlFor="whatsapp">WhatsApp Number</label>
                  <input
                    type="number"
                    id="whatsapp"
                    placeholder="Enter your WhatsApp number"
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                  />
                  {errors.whatsapp && (
                    <span className="error-text">{errors.whatsapp}</span>
                  )}

                  {/* Submit button */}
                  <button type="submit">Buy now ₹199.00</button>
                </form>
              </div>
            </div>
          </div>
          <Container fluid className="">
            <Row className="what_you_get">
              <h3 className="text-start">What you will get:</h3>
              <Col>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ul>
                    <li>Self-Discovery and Career Clarity</li>
                    <li>Measure and Analyze Your Progress</li>
                    <li>Develop Your Expertise</li>
                    <li>Network Effectively</li>
                    <li>Create High-Quality Content</li>
                  </ul>
                  <ul>
                    <li>Create a Compelling Personal Brand Story</li>
                    <li>Build a Strong Online Presence</li>
                    <li>Build Trust and Credibility</li>
                    <li>Leverage Your Unique Selling Proposition (USP)</li>
                    <li>Market Research and Understanding Current Trends</li>
                  </ul>
                </div>
              </Col>
              <Col>{/* This column is intentionally left empty */}</Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container fluid>
        <Row className="text-center text-white bg-dark py-5">
          <Col>
            <div className="contact-info d-flex justify-content-center align-items-center">
              <span>Contact Us:</span>
              <FaEnvelope className="mx-2" />
              <span>hello@connectingdotsbs.com</span>
              <FaPhoneAlt className="mx-2" />
              <span>+91 9822666114</span>
            </div>
            <div className="mt-3">
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7229447412774260736/?actorCompanyId=104370310">
                <i
                  class="fa-brands fa-linkedin"
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: "white",
                  }}
                ></i>
              </a>
              <a href="https://www.facebook.com/permalink.php?story_fbid=pfbid025QdaEAPKZo5wWgDJbogKGeX8G79pT9M2w33p8xvWr5EDjBQzQB9mhe3c7SJ6owoHl&id=61564225852198">
                <i
                  class="fa-brands fa-facebook"
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: "white",
                  }}
                ></i>
              </a>
              <a href="https://www.instagram.com/p/C-pgbMLIFF9/?fbclid=IwY2xjawEpoUBleHRuA2FlbQIxMAABHT0XpbvQZgYldNPSnX_565dcZjBZnOTZtcwZj4mXt7jBq0_POVkC9Gd1-g_aem_LmqAG-93pG0EF7BlyEFwZw&img_index=4">
                <i
                  class="fa-brands fa-instagram"
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: "white",
                  }}
                ></i>
              </a>
              <a href="https://www.youtube.com/@ConnectingCareers">
                <i
                  class="fa-brands fa-youtube"
                  style={{
                    fontSize: "30px",
                    marginRight: "10px",
                    color: "white",
                  }}
                ></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentPage;
