import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const CustomPage = () => {
  return (
    <Container fluid className="custom-page">
      <Row className="align-items-center justify-content-center custom-section">
        {/* Left section: Image */}
        <Col md={6} className="text-center">
          <div className="left-section">
            <img
              src="/group-1000006094-1@2x.png"
              alt="Professional"
              className="img-fluid"
            />
          </div>
        </Col>

        {/* Right section: Form */}
        <Col md={4} className="form-section">
          <div className="form-container">
            <Form>
              <Form.Group controlId="amount">
                <Form.Label>Amount</Form.Label>
                <Form.Control type="text" placeholder="199" />
              </Form.Group>

              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Your Name" />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter Your Email" />
              </Form.Group>

              <Form.Group controlId="whatsapp">
                <Form.Label>WhatsApp Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your WhatsApp Number"
                />
              </Form.Group>

              <Button variant="danger" type="submit" className="buy-btn">
                Buy Now ₹199.00
              </Button>
            </Form>
          </div>
        </Col>
      </Row>

      {/* Row for "What you will get" Section */}
      <Row className="what-you-get align-items-center">
        <Col md={12} className="text-center">
          <h3>What you will get:</h3>
        </Col>

        <Col md={5} className="mx-auto">
          <ul className="features-list">
            <li>Self-Discovery and Career Clarity</li>
            <li>Measure and Analyze Your Progress</li>
            <li>Develop Your Expertise</li>
            <li>Network Effectively</li>
            <li>Create High-Quality Content</li>
          </ul>
        </Col>
        <Col md={5} className="mx-auto">
          <ul className="features-list">
            <li>Create a Compelling Personal Brand Story</li>
            <li>Build a Strong Online Presence</li>
            <li>Build Trust and Credibility</li>
            <li>Leverage Your Unique Selling Proposition (USP)</li>
            <li>Market Research and Understanding Current Trends</li>
          </ul>
        </Col>
      </Row>

      {/* Footer Section */}
      <footer className="footer-section">
        <Row className="text-center">
          <Col>
            <div className="social-icons">
              <FaFacebook />
              <FaInstagram />
              <FaYoutube />
            </div>
            <div className="contact-info">
              <p>Contact Us: hello@connectingdotsbs.com | +91 9822666114</p>
            </div>
          </Col>
        </Row>
      </footer>
    </Container>
  );
};

export default CustomPage;
